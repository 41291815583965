<template>
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>
    <!-- <Header></Header>
    <Menu></Menu> -->
    <div class="page-wrapper">
      <div class="container-fluid position-relative">
        <div class="row page-titles">
          <div class="col-md-5 align-self-center"></div>
          <div class="col-md-7 align-self-center text-end">
            <div class="d-flex justify-content-end align-items-center">
              <ol class="breadcrumb justify-content-end">
                <li class="fw-bold h3">
                  <span 
                    >Transactions</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="icon">
          <a href="javascript:void(0)" class="back h4" @click="$router.go(-1)"
            ><box-icon name="left-arrow-alt" animation="tada"></box-icon
            >Retour</a
          >
        </div>
      </div>
  
      <div class="row container">
        <div class="col-md-12">
         
          <div class="table-responsive">
            <table
              id="MyTableData"
              class="table"
            
            >
              <thead>
                <tr>
                  <th class="bg-light">Date de transaction</th>
                  <th class="bg-light">Type de l'opération</th>
                  <th class="bg-light">Montant</th>
                   <th class="bg-light">Bordereau</th>
                   <th class="bg-light">Statut</th>
                   <th class="bg-light">Approuvée le</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listTransactionApprouve" :key="index">
                  
                  <td>{{ new Date(item.created_at).toLocaleDateString('fr') }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ moneyFormat.format(item.amount) }} Fcfa</td>
                  <td>
                    <span v-if="item.proof">
                        <n-image :src="'https://backend.invest-ci.com/public/'+item.proof"   width="50" :alt="item.proof" />
                    </span>
                    <span v-else class="badge bg-danger">x</span>
                    
                  </td>
                  <td>
                    <span class="badge bg-dark" v-if="item.state == 'en attente'">{{ item.state }}</span>
                    <span class="badge bg-success" v-if="item.state == 'validé'">Approuvé</span>
                </td>
                  <td>
                  <span class="badge bg-danger" v-if="item.state == 'en attente'">En attente d'approbation</span>
                    <span v-if="item.state == 'validé'">{{new Date(item.updated_at).toLocaleDateString('fr')}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </template>
  <script>

  import axios from "axios";
  import { lien } from "/src/assets/api.js";
  import $ from "jquery";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  export default {
    name: "TransactionApprouvesByAdmin",
    data() {
      return {
        listTransactionApprouve: [],
        isLoading: false,
        moneyFormat: new Intl.NumberFormat("de-DE"),
        user: null,
        solde: null,
      };
    },
    components: {
      Loading,
    },
    methods: {
      getTransactionsApprouve() {
        axios.get(lien + "/api/operations-historique")
        .then((res) => {
        //console.log("LISTEOPERATION", res);
        this.listTransactionApprouve = res.data.data;
        //console.log("LISTEOPERATION", this.listTransactionApprouve);
        this.isLoading = false;
        setTimeout(function () {
          $("#MyTableData").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,
            order: [],
            language: {
              décimal: "",
              emptyTable: "Aucune donnée disponible dans le tableau",
              infoEmpty: "Showing 0 to 0 of 0 entries",
              info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
              infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
              infoPostFix: "",
              thousands: ",",
              lengthMenu: "Afficher les entrées du _MENU_",
              loadingRecords: "Loading...",
              processing: "Processing...",
              search: "Chercher :",
              stateSave: true,
              zeroRecords: "Aucun enregistrement correspondant trouvé",
              paginate: {
                first: "Premier",
                last: "Dernier",
                next: "Suivant",
                previous: "Précédent",
              },
              aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
              },
            },
          });
        }, 10);
      });
      },
    //   approuveVersement(idVersement){
    //     let data = {
    //      state:"validé"
    //     }
    //     //console.log(data,idVersement)
       
    //     axios.post(lien + "/api/operation-update/"+idVersement,data)
    //     .then(res=>{
    //         //console.log(res)
    //         if(res.data.status == 'true'){
    //             this.listTransactionApprouve.forEach(item=>{
    //                 if(item.id === idVersement){
    //                     item.state = "validé"
    //                 }
    //             })
    //         }
    //     })
    //     .catch(err=>{
    //         console.log(err)
    //     })
    //   }
    },
    created() {
      this.getTransactionsApprouve();
      this.isLoading = true;
  
    },
  };
  </script>
  <style scoped>
  .check{
    cursor: pointer;
  }
  .my_footer {
    position: relative !important;
    bottom: -39em;
  }
  .icon {
    position: absolute;
    left: 1em;
    top: 0;
  }
  .table {
    border: thin solid rgba(139, 139, 139, 0.63) !important;
  }
  th,
  td {
    border: thin solid rgba(141, 140, 140, 0.692) !important;
  }
  .user {
    text-align: left;
  }
  input,
  select {
    border: 1px solid black !important;
  }
  .form-group {
    text-align: left !important;
  }
  label{
    font-weight: bold !important;
  }
  </style>
  